<import from="./project-list-item"></import>

<section class="google-maps">
    <map class="${showMap?'show': ''}" service.bind="mapService"
         latitude="47.3769"
         longitude="8.5417"
         zoom="8">
    </map>
    <div class="filters filters_projects ${showMap?'': 'show'} ">
        <div class="header">
            <div class="row">
                <div class="item">
                    <div class="input-group">
                        <div class="input-group-append">
                            <button class="btn btn-secondary" type="button">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                        <input type="text" class="form-control" value.bind="name" placeholder="Projektname">

                        <button type="button" class="close" click.delegate="clearName()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mt-0 toggle toggle-primary toggle-simple" data-plugin-toggle>
                </div>
            </div>

            <div class="d-flex justify-content-stretch flex-column ">
                <div class="item flex-grow-1">
                    <div class="dropdown d-grid gap-1">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdown-category"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            <span if.bind="activeCategory">${activeCategory.name}</span>
                            <span else>Kategorie</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="Ort">
                            <li class="dropdown-item" click.delegate="setCategory()">Keine Kategorie</li>
                            <li class="dropdown-item"
                                click.delegate="setCategory(category)"
                                repeat.for="category of listController.categories">
                                <span>${ category.name }</span>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="item flex-grow-1">
                    <div class="dropdown d-grid gap-1">
                        <button class="btn full-width btn-secondary dropdown-toggle" type="button" id="dropdown-orte"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            <span if.bind="activeLocation">${activeLocation.name}</span>
                            <span else>Ort</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="Ort">
                            <li class="dropdown-item" click.delegate="setLocation()">Kein Ort</li>

                            <li class="dropdown-item"
                                click.delegate="setLocation(location)"
                                repeat.for="location of listController.locations">
                                <span>${ location.name }</span>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="item flex-grow-1">
                    <div class="dropdown d-grid gap-1">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdown-kunden"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            <span if.bind="activeClient">${activeClient.name}</span>
                            <span else>Kunde</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="Ort">
                            <li class="dropdown-item" click.delegate="setClient()">Kein Kunde</li>
                            <li class="dropdown-item"
                                click.delegate="setClient(client)"
                                repeat.for="client of listController.clients">
                                <span>${ client.name }</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="mobile-map d-lg-none" click.delegate="visibilityMap()">
                    <span class="${showMap?'no-text': ''} g-map-text"><i class="fa fa-map-marker-alt" aria-hidden="true"></i> Karte</span>
                    <span class="${showMap?'': 'no-text'} projects-text"><i class="fa fa-list" aria-hidden="true"></i> Projekt Liste</span>

                </div>
               
            </div>

        </div>
        <div class="content">
            <ul class="projects list-group ${showMap?'hide': ''}  ">

                <project-list-item repeat.for="project of listController.list"
                                   index.bind="$index"
                                   map-service.bind="mapService"
                                   latitude.bind="project.latitude"
                                   longitude.bind="project.longitude"
                                   project.bind="project">
                </project-list-item>

            </ul>
        </div>

    </div>

</section>
